.container {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.89);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content {
	position: relative;
	max-width: 80vw;
}

.imageContainer {
	position: relative;
}

.image {
	width: 100%;
	height: auto;
	max-height: 60vh;
	object-fit: contain;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 32px;
	user-select: none;
}

.thumb {
	width: 80px;
	height: 80px;
	object-fit: cover;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	user-select: none;
	transition: filter 0.2s;
	filter: brightness(0.77);
}

.thumb:hover {
	filter: brightness(1);
}

.arrowLeft {
	position: absolute;
	font-size: 32px;
	color: @grey-1;
	left: -48px;
	top: 50%;
	margin-top: -16px;
	transition: opacity 0.4s;
}

.arrowRight {
	position: absolute;
	font-size: 32px;
	color: @grey-1;
	right: -48px;
	top: 50%;
	margin-top: -16px;
	transition: opacity 0.4s;
}

.slider {
	overflow-y: scroll;
}

.slider > div {
	width: fit-content;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-y: visible;
	gap: 8px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;