.container {
	width: 100%;
}

.content {
	max-width: 960px;
	margin: 0 auto;

	h2 {
		font-family: agenda, sans-serif;
		font-weight: 700;
		font-size: calc(30px * 1.1);
		line-height: 38px;
		font-weight: 700;
		color: @grey-9;
		padding-bottom: 12px;
	}

	h3 {
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;
		color: @grey-8;
		padding-top: 16px;
	}

	p,
	ul,
	ol {
		font-size: 16px;
		line-height: 24px;
	}

	blockquote {
		padding: 16px 0px;
	}

	blockquote p {
		text-align: center;
		font-family: agenda, sans-serif;
		font-weight: 600;
		font-size: calc(24px * 1.1);
		line-height: 32px;
		font-weight: 600;
		color: @grey-9;
	}

	figure {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	table {
		width: 100%;
		margin: 24px 0px;
	}

	tr {
		border-bottom: 1px solid @grey-4;
	}

	tr:first-of-type {
		background-color: @grey-2;
		td {
			color: @grey-9;
			font-weight: 600;
		}
	}

	td {
		font-size: 14px;
		padding: 16px;
		color: @grey-8;
	}

	td p {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
	}

	figcaption {
		color: @grey-7;
		margin-bottom: 16px;
		max-width: 540px;
		text-align: center;
	}

	img {
		width: 100%;
		height: 100%;
		max-width: 540px;
		max-height: 540px;
		margin: 32px 0px 12px;
	}
}

.embed {
	max-width: 670px;
	margin: 0px auto;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;