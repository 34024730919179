.container {
	margin: 48px 0px;
}

.wrapper {
	position: relative;
	padding-bottom: 56.25%; // 16:9
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;