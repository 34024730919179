.container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 12px;
	border-radius: 4px;
	overflow: hidden;
	min-height: 270px;
	transition: filter 0.2s;
	@media screen and (min-width: 991px) {
		min-height: 350px;
		padding: 24px;
	}
	@media screen and (min-width: 1068px) {
		min-height: 430px;
		padding: 32px;
	}
}

.container:hover {
	filter: brightness(0.95);
}

.spacer {
	width: fit-content;
}

.default {
	width: calc(50vw - 36px);
	@media screen and (max-width: 576px) {
		width: 100%;
	}
	@media screen and (max-width: 768px) {
		min-height: calc(100% - 78px - 78px);
		justify-content: flex-start;
	}
	@media screen and (min-width: 1068px) {
		width: calc(50vw - 36px);
		max-width: calc(584px - 36px);
	}
	@media screen and (min-width: 1200px) {
		width: calc(584px - 16px);
		max-width: none;
	}
}

.full {
}

.big {
}

.imageContainer {
	@media screen and (max-width: 768px) {
		position: relative;
	}
}

.image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@media screen and (max-width: 768px) {
		position: relative;
		width: calc(100% + 24px);
		height: 200px;
		margin: -12px -12px 12px;
	}
	object-fit: cover;
	transition: all 0.4s;
}

.container:hover .image {
	transform: scale(1.01);
}

.imageOverlay {
	position: absolute;
	top: 0;
	left: -12px;
	right: -12px;
	bottom: 0px;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.content {
	position: relative;
	z-index: 1;
	@media screen and (min-width: 991px) {
		max-width: calc(100% - 80px);
	}
}

.text {
	margin-top: 8px;
	white-space: pre-line;
}

.link {
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 12px 16px;
}

.button {
	margin-top: 12px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;