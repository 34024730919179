.container {
	max-width: 1200px;
	margin: 0px auto;
	@media screen and (min-width: 320px) {
		padding: 0px 20px;
	}
}

.navbar {
	padding: 16px 0px;
}

.logo {
	max-height: 40px;
	max-width: 220px;
	@media screen and (min-width: 1068px) {
		max-height: 56px;
	}
}

.menu {
	margin: 0px 64px;
}

.menuItem {
	margin: 0px 24px;
}

.image {
	width: 100%;
	height: auto;
}

.content {
	text-align: center;
	padding: 32px 44px 48px;
}

.subtitle {
	font-family: zooja-pro, sans-serif;
	font-size: 26px;
	@media screen and (min-width: 768px) {
		font-size: 32px;
	}
	@media screen and (min-width: 1068px) {
		font-size: 40px;
	}
}

.ctaButton {
	margin-top: 6px;
}

@primary-color: #1f6c04;@secondary-color: #268f01;@link-color: #1f6c04;@layout-body-background: #fff;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.911);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
	0 9px 28px 8px rgba(0, 0, 0, 0.05);@grey-1: #ffffff;@grey-2: #fafafa;@grey-3: #f5f5f5;@grey-4: #f0f0f0;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@grey-9: #262626;